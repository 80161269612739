<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <AConfirmation :isShow="isPendingDelete" @cancel="cancelSubmit" @confirm="deleteReward"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row v-else>
            <v-col
            class="mx-auto"
            cols="12"
            sm="6">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="closeDetail">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </div>
                <v-card class="pa-3">
                    <v-toolbar class="secondary white--text " >
                        <v-toolbar-title class="font-weigh-bold text-h5">
                            Claim Reward
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="mt-3 ma-3">
                        
                       <div 
                            class="d-flex justify-start">
                            <v-text-field 
                                v-model="reward.name"
                                dense 
                                outlined
                                label="Name">
                            </v-text-field>
                       </div>
                       <div
                            class="d-flex justify-start">
                            <v-text-field
                                type="text"
                                v-model="reward.description"
                                outlined
                                label="Description"
                                dense>

                            </v-text-field>
                       </div>
                       <div
                            class="d-flex justify-start">
                            <v-select
                                dense
                                outlined
                                :items="typeOptions" 
                                v-model="reward.type"
                                label="Type">

                            </v-select>
                       </div>
                       <div
                            class="d-flex flex-column justify-start">
                            <v-text-field 
                                :disabled="reward.pre_order"
                                style="margin-bottom:-10px;margin-top:10px"
                                type="number"
                                dense
                                outlined
                                label="Quantity"
                                v-model="reward.quantity">
                            </v-text-field>
                            <v-checkbox
                                v-model="reward.pre_order"
                                dense 
                                outlined
                                style="margin-top:-10px;margin-bottom:10px"
                                label="Pre-Order">
                            </v-checkbox>
                       </div>
                       <div 
                            class="d-flex justify-start">
                            <v-text-field
                                type="number"
                                dense
                                outlined 
                                label="Required Points"
                                v-model="reward.required_points">
                            </v-text-field>
                       </div>
                       <div
                            class="d-flex justify-start">
                            <v-text-field
                                type="number"
                                dense
                                outlined 
                                label="priority"
                                min="10"
                                v-model="reward.order">
                            </v-text-field>
                       </div>
                       <v-row>
                            <v-col>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        @click:prepend="redirectStartDate"
                                        @click="redirectStartDate"
                                        dense
                                        outlined
                                        label="Start Date"
                                        v-model="reward.start_at">
                                    </v-text-field>
                                </div>
                                <v-dialog 
                                    v-model="isStartDate"
                                    height="auto"
                                    width="auto">
                                    <div 
                                        class="d-flex justify-center">
                                        <v-card
                                            class="pa-3">
                                            <div class="d-flex flex-column">
                                                <p>
                                                    Start Date
                                                </p>
                                                <div>
                                                    <v-date-picker
                                                        flat 
                                                        v-model="reward.start_at"
                                                        :landscape="true"
                                                        :reactive="false">
                                                    </v-date-picker>
                                                </div>
                                                <slot
                                                    name="footer">
                                                    <div
                                                        class="d-flex justify-end my-3"
                                                        color="white">
                                                        <v-btn
                                                            color="primary"
                                                            plain
                                                            @click="()=>{
                                                                isStartDate=false;
                                                            }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            @click="confirmStartDate">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </slot>
                                            </div>
                                        </v-card>
                                    </div>
                                   
                                </v-dialog>
                            </v-col>
                            <v-col>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        prepend-icon="mdi-calendar"
                                        @click:prepend="redirectEndDate"
                                        @click="redirectEndDate"
                                        dense 
                                        outlined
                                        label="End Date"
                                        v-model="reward.end_at">

                                    </v-text-field>
                                    <v-dialog 
                                    v-model="isEndDate"
                                    height="auto"
                                    width="auto">
                                    <div 
                                        class="d-flex justify-center">
                                        <v-card
                                            class="pa-3">
                                            <div class="d-flex flex-column">
                                                <p>
                                                    End Date
                                                </p>
                                                <div>
                                                    <v-date-picker
                                                        flat 
                                                        v-model="reward.end_at"
                                                        :landscape="true"
                                                        :reactive="false">
                                                    </v-date-picker>
                                                </div>
                                                <slot
                                                    name="footer">
                                                    <div
                                                        class="d-flex justify-end my-3"
                                                        color="white">
                                                        <v-btn
                                                            color="primary"
                                                            plain
                                                            @click="()=>{
                                                                isEndDate=false;
                                                                reward.end_at = null;
                                                            }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            @click="confirmEndDate">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </slot>
                                            </div>
                                        </v-card>
                                    </div>
                                   
                                </v-dialog>
                                </div>
                            </v-col>
                       </v-row>
                       <div 
                            class="d-flex justify-start">
                            <v-file-input
                                :rules="rulesImage"
                                accept="image/png"
                                color="primary"
                                v-model="attachment"
                                outlined
                                mutliple
                                label="Upload the new reward image"
                                >

                            </v-file-input>
                       </div>
                        <!-- <div 
                            class="d-flex justify-start mb-3 pb-3">
                           
                           
                       </div> -->
                        <div class="d-flex justify-end pt-4">
                           
                            <div>
                                <v-btn color="primary" plain @click="()=>{
                                    $router.go(-1)
                                }">
                                    Cancel
                                </v-btn>
                                <v-btn color="primary" :loading="api.isLoading" @click="validateInput">
                                    Confirm
                                </v-btn>
                            </div>
                        </div>
                        <!-- <div class="d-flex justify-end">
                          
                        </div> -->
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    reward:{
        name:null,
        description:null,
        type:null,
        quantity:null,
        required_points:null,
        order:null,
        start_at:null,
        end_at:null,
        pre_order:false,
    },
    isPending:false,
    isPendingDelete:false,
    tempStartDate:null,
    tempEndDate:null,
    allowedRolesDelete:["developer","operation"],
    attachment:null,
    isStartDate:false,
    isEndDate:false,
    rulesImage:[
            value=>value[0].size < 10000000 || 'File size should be less than 10 MB!',
            value=>value.length<=3 || 'Total files that can be uploaded should be less than 3 files',
        ],
    headersTeam:[
        {
            text:'Name',
            value:'memberName',
            align:'start',
        },{
            text:'Email',
            value:'memail',
        }
    ],
    statusOptions:[
        'Pending',
        'Shipping',
        'Completed',
    ],
    typeOptions:[
        'E-voucher',
        'Coupon',
        'Gift',
    ],
    api:
        {
            isLoading : false,
            isError:false,
            error:null,
            url:null,
            isSuccesful:false,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getReward"){
                this.reward = resp.data;
                let tempStartAt = this.$_.clone(this.reward.start_at)
                this.reward.start_at = tempStartAt.split(" ")[0]
                let tempEndAt = this.$_.clone(this.reward.end_at);
                this.reward.end_at = tempEndAt.split(" ")[0];
             }
            if(resp.class=="addReward"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Add New Reward"
            }
            if(resp.class=="removeReward"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Delete Reward";
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
    },
    methods: {
        fetch(){
        },
        validateInput(){
            this.isPending = true
        },
        validateInputDelete(){
            this.isPendingDelete = true;
        },
        submit(){
            this.isPending = false;
            let updateRewardStatusApi = this.updateRewardStatus();
            this.$api.fetch(updateRewardStatusApi);
        },
        deleteReward(){
            this.isPendingDelete = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/rewards/"+this.$router.history.current.params.id;
            this.$api.fetch(tempApi);
        },
        updateRewardStatus(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/rewards/new/"+this.$router.history.current.params.id;
            let formData = new FormData;
            formData.append('name',this.reward.name);
            formData.append('quantity',this.reward.quantity);
            formData.append('type',this.reward.type);
            formData.append('description',this.reward.description);
            formData.append('start_at',this.reward.start_at);
            formData.append('order',this.reward.order);
            formData.append('required_points',this.reward.required_points);
            if(this.reward.end_at!=null){
                formData.append('end_at',this.reward.end_at);
            }
            formData.append('pre_order',this.reward.pre_order);
            if(this.attachment!=null){
                formData.append('image',this.attachment);
            }
            tempApi.params = formData;
            return tempApi;
        },
        closeDetail(){
            this.$router.go(-1);
        },
        cancelSubmit(){
            this.isPending = false;
            this.isPendingDelete = false;
        },
        redirectStartDate() {
            this.isStartDate = true;
        },
        redirectEndDate() {
            this.isEndDate = true;
        },
        confirmStartDate(){
            this.isStartDate = false;
        },
        confirmEndDate(){
            this.isEndDate = false;
        }

    }
}
</script>